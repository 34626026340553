#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  display: flex;
  background: #687eff;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0vw;
  height: 1vw;
  background-color: transparent
}

::-webkit-scrollbar-thumb {
  background-color: #fff;
  filter: drop-shadow(0 0 15px #687EFF) drop-shadow(0 0 20px #687EFF);
}
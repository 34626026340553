@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('../public/font/Inter-Thin.ttf') format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Cera';
  src: local('Intro'), url('../public/font/intro.ttf') format('truetype');
}

:root {
  --color1: #687eff;
  --color2: #060606;
  --color3: #131313;
  --color4: #949CB0;
  --color5: #ffffff;
}

header {
  position: fixed;
  display: flex;
  width: 89vw;
  height: 10vh;
  background: var(--color3);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  z-index: 8;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 5vw;
  padding-left: 1vw;
  margin-top: 2vh;
  border-radius: 25px;
}

.nav {
  padding: 0vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  width: 15vw;
  list-style-type: none;
}

.nav .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid;
  border-color: var(--color5);
  color: var(--color5);
  font-size: 1.8vw;
  transition: transform 0.5s ease, background-color 0.5s ease, border-color 0.5s ease, filter 0.5s ease;
}

.nav .circle:hover,
.nav .circle:focus {
  border-color: var(--color1);
  background-color: var(--color1);
  transform: scale(1.1);
  filter: drop-shadow(5px -1px 0px var(--color5));
}

.navLogo {
  cursor: pointer;
}

.navLogo a {
  text-decoration: none;
  width: 15vw;
  height: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navLogo a:focus,
.navLogo a:hover {
  color: var(--color1);
}

.navLogo img {
  transform: scale(0.5);
}

.navLogo span {
  width: 4vw;
  height: 4vw;
  background: var(--color1);
  color: var(--color3);
  font-family: 'Cera';
  font-size: 64px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.navLogo div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 11vw;
  height: 4vw;
}

.navLogo div p {
  width: 100%;
  height: 25%;
  font-family: 'Cera';
  font-size: 32px;
  color: var(--color1);
  background: none;
  text-align: center;
}

.navLogo div span {
  width: 100%;
  height: 25%;
  font-family: 'Inter';
  color: var(--color5);
  width: 100%;
  text-transform: uppercase;
  font-size: 16px;
  background: none;
  text-align: center;
}

.burger {
  display: none;
}

.nav-list {
  display: flex;
  width: 65vw;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  list-style: none;
  text-transform: uppercase;
}

.nav-item {
  font-family: 'Cera';
  font-size: 24px;
}

.square {
  display: none;
  align-items: center;
  justify-content: center;
  width: 7vw;
  height: 7vw;
}

.nav .square .logo {
  font-size: 7vw;
  color: #fff;
}

.nav-lang {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 8vw;
  align-items: center;
  height: 3vh;
  overflow: hidden;
  cursor: pointer;
}

.nav-lang div {
  color: var(--color5);
  font-family: 'Cera';
  font-size: 24px;
}

.nav-lang span {
  width: 2px;
  height: 3vh;
  background-color: var(--color5);
}

.nav-lang .active {
  color: var(--color1);
  text-shadow: 3px -1px 0 var(--color5);
}

.nav-link {
  text-decoration: none;
  color: var(--color5);
  transition: color 0.5s ease, text-shadow 0.5s ease;
}

.nav-link:hover,
.nav-link:focus {
  color: var(--color1);
  text-shadow: 2px -1px 0px var(--color5);
}

.About {
  position: absolute;
  width: 100vw;
  height: 85vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 15vh;
}

.About div {
  display: flex;
}

.About .aboutText {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 40vh;
  transform: translateX(-60%);
}

.About .atomImg {
  transform: scale(3);
  width: 13vw;
  z-index: 3;
  position: absolute;
  bottom: 25vh;
  right: 15vw;
}

.About .cosmoImg {
  transform: scale(2) rotate(180deg);
  width: 10vw;
  height: 30vh;
  z-index: 0;
  position: absolute;
  top: 25vh;
  left: 15vw;
}

.About .aboutText h2 {
  font-family: 'Cera';
  font-size: 16vw;
  color: var(--color3);
  text-shadow: 10px -4px 0px var(--color5);
  margin: 0;
  width: 40vw;
  height: 35vh;
  z-index: 1;
  transform: translateY(25%);
  margin-bottom: 9vh;
}

.About .aboutText p {
  font-family: 'Inter';
  font-size: 1.4vw;
  color: var(--color5);
  margin: 0;
  white-space: pre-line;
  width: 55vw;
  transform: translateX(15%);
}

.About .atomImg img {
  z-index: 2;
}

.About .aboutText p span {
  font-family: 'Cera';
  color: var(--color3);
  text-shadow: 3px -1px 0px var(--color5);
}

.Ideology {
  top: 5vh;
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Ideology .IdeoDiv {
  width: 90vw;
  min-height: 95vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Ideology .IdeoDiv:nth-child(2) {
  flex-direction: row-reverse;
}

.Ideology .IdeoDiv div {
  width: 60%;
  z-index: 6;
}

.Ideology .IdeoDiv div h2 {
  font-family: 'Cera';
  color: var(--color3);
  font-size: 5vw;
  text-shadow: 7px -3px 0px var(--color5);
  margin: 0;
}

.Ideology .IdeoDiv div p {
  font-family: 'Inter';
  color: var(--color5);
  font-size: 28px;
  width: 100%;
}

.Ideology .IdeoDiv .ideoImg {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M773 559q-111 59-139 163.5T511 793q-95-34-236.5-30t-180-129.5Q56 500 191 437t188.5-102.5q53.5-39.5 117-29t221-34q157.5-44.5 162 92T773 559Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23687eff%22 d=%22M773 559q-111 59-139 163.5T511 793q-95-34-236.5-30t-180-129.5Q56 500 191 437t188.5-102.5q53.5-39.5 117-29t221-34q157.5-44.5 162 92T773 559Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  transform: scale(1.8);
  z-index: 0;
}

.Ideology .IdeoDiv:nth-child(2) .ideoImg {
  background-image: url('data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M819 554.5Q651 609 635 737t-117.5 75Q416 759 258 775T74 645.5Q48 500 196 443.5t167-182q19-125.5 118-66T656 293q76 38 203.5 122.5t-40.5 139Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23687eff%22 d=%22M819 554.5Q651 609 635 737t-117.5 75Q416 759 258 775T74 645.5Q48 500 196 443.5t167-182q19-125.5 118-66T656 293q76 38 203.5 122.5t-40.5 139Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.Ideology .IdeoDiv:nth-child(3) .ideoImg {
  background-image: url('data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M754 599q18 99-43.5 228.5T502 952.5q-147-4.5-156.5-169t-7-224q2.5-59.5-103-194t25-168q130.5-33.5 230-4t116.5 123Q624 410 680 455t74 144Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23687eff%22 d=%22M754 599q18 99-43.5 228.5T502 952.5q-147-4.5-156.5-169t-7-224q2.5-59.5-103-194t25-168q130.5-33.5 230-4t116.5 123Q624 410 680 455t74 144Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
}

.Ideology .IdeoDiv .ideoImg img {
  width: 100%;
  position: relative;
  z-index: 2;
  transform: scale(0.5);
}

.Product {
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  align-items: center;
}

.mvp {
  width: 90vw;
  padding: 0 5vw;
  min-height: 80vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mvp div {
  width: 55%;
  z-index: 2;
}

.mvp div h2 {
  font-family: 'Inter';
  color: var(--color5);
  font-size: 5vw;
  margin: 0;
}

.mvp div h2 span {
  font-family: 'Cera';
  color: var(--color3);
  font-size: 5vw;
  text-shadow: 5px -1px 0px var(--color5);
  text-transform: uppercase;
}

.mvp div p {
  font-family: 'Inter';
  color: var(--color5);
  font-size: 1.5vw;
  margin: 0;
  margin-bottom: calc(1.5vw+8px);
}

.mvp .mvpImage {
  width: 45%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox=%220 0 1000 1000%22 xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cdefs%3E%3CclipPath id=%22a%22%3E%3Cpath fill=%22currentColor%22 d=%22M759 614.5q56 114.5-73.5 87t-186-26.5q-56.5 1-183 25.5t-152-88q-25.5-112.5 25-207t156-74.5Q451 351 529 261.5t117.5 7q39.5 96.5 48 164t64.5 182Z%22%2F%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg clip-path=%22url(%23a)%22%3E%3Cpath fill=%22%23687eff%22 d=%22M759 614.5q56 114.5-73.5 87t-186-26.5q-56.5 1-183 25.5t-152-88q-25.5-112.5 25-207t156-74.5Q451 351 529 261.5t117.5 7q39.5 96.5 48 164t64.5 182Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  transform: scale(2.2) translate(0%, 5%);
  z-index: 0;
  height: 65vh;
}

.mvp .mvpImage img {
  width: 20vw;
  position: relative;
  z-index: 2;
  height: 20vw;
  transform: scale(0.9) translate(60%, -5%);
}

.mvp .mvpImage svg {
  z-index: 0;
  transform: translate(-100%, -0%) scale(6.5);
}

.services {
  width: 90vw;
  padding: 0 5vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.services h2 {
  font-family: 'Cera';
  color: var(--color3);
  font-size: 64px;
  text-shadow: 5px -1px 0px var(--color5);
}

.services .servBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 85%;
}

.services .servBlock div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.services .servBlock div span {
  font-family: 'Inter';
  font-size: 4vw;
  color: var(--color5);
  margin: 0;
  margin-bottom: 16px;
}

.services .servBlock div ul {
  list-style-type: none;
  font-family: 'Inter';
  color: var(--color5);
  margin: 0;
  font-size: 32px;
  min-height: 15vw;
  display: flex;
  flex-direction: column;
  text-transform: lowercase;
  line-height: 1.5;
}

.services .servBlock div ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.services .servBlock div ul li svg {
  color: var(--color3);
  transform: scale(0.8) translate(-20%, 10%);
}

.bot {
  width: 90vw;
  padding: 0 5vw;
  min-height: 50vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.bot div {
  width: 55%;
  display: flex;
  align-items: center;
  flex-direction: row;
  line-height: 1.5;
}

.bot div:nth-child(1) {
  flex-direction: column;
  z-index: 2;
}

.bot div:nth-child(2) {
  width: 45%;
  transform: scale(3) translate(10%, 0%);
  max-height: 65vh;
  z-index: 0;
}

.bot div p {
  width: 100%;
  font-family: 'Inter';
  font-size: 1.5vw;
  color: var(--color5);
  margin: 0;
}

.bot div p span {
  font-family: 'Cera';
  color: var(--color3);
  text-shadow: 3px -1px 0px var(--color5);
}

.bot div a {
  width: 30%;
  height: 4vw;
  font-family: 'Cera';
  text-transform: uppercase;
  border-radius: 50px;
  border: 2px solid var(--color3);
  background: var(--color3);
  color: var(--color5);
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 2vh;
}

.bot div a:hover,
.bot div a:focus {
  filter: drop-shadow(7px -3px 0 var(--color5));
}

.bot div img {
  width: 20vw;
  position: relative;
  z-index: 2;
  height: 20vw;
  transform: scale(0.7) translateX(40%);
}

.Instruction {
  width: 100vw;
  min-height: 80vh;
  margin-top: 15vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin-bottom: 15vh;
}

.Instruction .instructionDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 50%;
}

.Instruction .instructionDivMobile {
  display: none;
}

.Instruction .instructionDiv p {
  width: 40vw;
  font-family: 'Inter';
  font-size: 1.5vw;
  color: aliceblue;
  margin: 0%;
  cursor: pointer;
  margin-bottom: 32px;
}

.Instruction .instructionDiv div {
  display: flex;
  flex-direction: row;
  align-items: center;
  filter: grayscale(0.8);
  cursor: pointer;
}

.Instruction .instructionDiv .active {
  filter: grayscale(0);
}

.Instruction .instructionDiv .numInstruction {
  font-family: 'Cera';
  color: var(--color5);
  font-size: 1.5vw;
  width: 3vw;
  height: 3vw;
  background: #4754a1;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 1.5vw;
  margin-top: -32px;
}

.Instruction .instructionDiv p span {
  font-family: 'Cera';
  color: #4754a1;
  text-shadow: 3px -1px 0 var(--color5);
}

.Instruction .InstructionImg {
  width: 45vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(../public/image/bgTel.svg);
  background-position: center;
  transform: scale(1.5);
  background-repeat: no-repeat;
}

.Instruction .InstructionImg img {
  width: 80%;
  transform: skew(10deg, 0deg) scale(0.75);
  filter: drop-shadow(-15px 15px 0px var(--color2));
  z-index: 0;
  margin-left: 0vw;
}

@media screen and (max-width: 600px) {
  header {
    height: 8vh;
  }

  .navLogo {
    margin-top: 0vh;
    margin-left: 3vw;
  }

  .navLogo a {
    width: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .navLogo img {
    width: 30vw;
    transform: scale(1);
  }

  .nav-list {
    display: none;
  }

  .nav-item {
    font-size: 18px;
  }

  .nav {
    width: 40vw;
  }

  .nav .circle {
    width: 7vw;
    height: 7vw;
    font-size: 4vw;
  }

  .nav .square {
    display: block;
    z-index: 10;
  }

  .burger {
    display: flex;
    position: fixed;
    z-index: 9;
    width: 100vw;
    height: 100vh;
    background: var(--color3);
    top: 0;
    height: 0;
  }

  .burger .nav-list {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: var(--color2);
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    padding-top: 0vh;
    align-items: flex-start;
    justify-content: center;
    line-height: 3;
    padding-left: 14vw;
  }

  .nav-lang {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    align-items: center;
    height: 10vh;
    overflow: hidden;
    cursor: pointer;
  }

  .nav-lang div {
    color: var(--color5);
    font-family: 'Cera';
    font-size: 15vw;
  }

  .nav-item {
    font-size: 8vw;
    width: 60vw;
    height: 10vh;
  }

  .burger .square {
    display: flex;
    width: 11vw;
    height: 11vw;
    color: var(--color5);
    font-size: 11vw;
    position: absolute;
    right: 5vw;
    top: 5vh;
    z-index: 10;
  }

  .About {
    top: 10vh;
    width: 100vw;
    min-height: 100vh;
    flex-direction: column;
  }

  .About .cosmoImg {
    width: 60vw;
    top: 0vh;
    left: -5vw;
    z-index: 0;
    transform: scale(1) rotate(180deg);
  }

  .About .aboutText {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    position: absolute;
    top: 20vh;
    transform: translate(0, 0);
  }

  .About .aboutText h2 {
    font-size: 16vw;
    text-shadow: 5px -2px 0px var(--color5);
    transform: translate(-65%, 0);
    margin: 0;
    margin-bottom: 3vh;
  }

  .About .aboutText p {
    font-size: 4vw;
    width: 95vw;
    margin-top: 0vh;
    margin-left: 5vw;
    transform: translate(0, 0);
  }

  .About .aboutText p span {
    text-shadow: 3px -1px 0px var(--color5);
  }

  .About .atomImg {
    transform: scale(7);
    right: 40vw;
    bottom: 23vh;
  }

  .Product {
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    margin-bottom: 10vh;
  }

  .mvp {
    width: 100vw;
    padding: 0 1vw;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .mvp div {
    width: 90%;
    z-index: 2;
  }

  .mvp div h2 {
    font-family: 'Inter';
    color: var(--color5);
    font-size: 9vw;
    margin: 0;
    text-align: left;
  }

  .mvp div h2 span {
    font-family: 'Cera';
    color: var(--color3);
    font-size: 9vw;
    text-shadow: 3px -1px 0px var(--color5);
    text-transform: uppercase;
  }

  .mvp div p {
    font-family: 'Inter';
    color: var(--color5);
    font-size: 4vw;
    margin: 0;
  }

  .mvp .mvpImage {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    transform: scale(1.5) translate(0%, 0%);
    z-index: 0;
    height: 50vh;
  }

  .mvp .mvpImage img {
    width: 20vw;
    position: relative;
    z-index: 2;
    height: 20vw;
    transform: scale(3) translate(60%, 5%);
  }

  .services {
    width: 100vw;
    padding: 0 1vw;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
  }

  .services h2 {
    font-family: 'Cera';
    color: var(--color3);
    font-size: 9vw;
    text-shadow: 3px -1px 0px var(--color5);
    text-align: center;
  }

  .services .servBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90%;
    flex-wrap: wrap;
  }

  .services .servBlock div {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
  }

  .services .servBlock div span {
    font-family: 'Inter';
    font-size: 8vw;
    color: var(--color5);
    margin: 0;
    margin-bottom: 1vh;
  }

  .services .servBlock div ul {
    list-style-type: none;
    font-family: 'Inter';
    color: var(--color5);
    margin: 0;
    font-size: 4vw;
    margin-top: 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: lowercase;
    align-items: flex-start;
    transform: translateX(-15%);
    line-height: 1.5;
    min-height: 0;
  }

  .services .servBlock div ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  .services .servBlock div ul li svg {
    color: var(--color3);
    transform: scale(0.9) translate(-20%, 10%);
  }

  .bot {
    margin-top: 10vh;
    width: 98vw;
    padding: 0 1vw;
    min-height: 50vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
  }


  .bot div {
    width: 95%;
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .bot div:nth-child(1) {
    margin-top: 10vh;
    flex-direction: column;
    z-index: 2;
  }

  .bot div p {
    width: 100%;
    font-family: 'Inter';
    font-size: 4vw;
    color: var(--color5);
  }

  .bot div p span {
    font-family: 'Cera';
    color: var(--color3);
    text-shadow: 2px -1px 0px var(--color5);
  }

  .bot div a {
    width: 50%;
    height: 12vw;
    font-family: 'Cera';
    text-transform: uppercase;
    border-radius: 50px;
    border: 2px solid var(--color3);
    background: var(--color3);
    color: var(--color5);
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .bot div:nth-child(2) {
    transform: scale(4) translate(10%, 0%);
    max-height: 75vh;
    z-index: 0;
    width: 50vw;
    height: 50vw;
  }

  .bot div img {
    width: 30vw;
    position: relative;
    z-index: 2;
    height: 30vw;
    transform: scale(0.7) translate(20%, 0%);
  }

  .Ideology {
    top: 15vh;
    position: absolute;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Ideology .IdeoDiv {
    width: 100vw;
    min-height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25vh;
  }

  .Ideology .IdeoDiv div {
    width: 90%;
    z-index: 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
  }

  .Ideology .IdeoDiv div h2 {
    font-family: 'Cera';
    color: var(--color3);
    font-size: 8vw;
    text-shadow: 3px -1px 0px var(--color5);
    margin: 0;
    text-align: center;
  }

  .Ideology .IdeoDiv div p {
    font-family: 'Inter';
    color: var(--color5);
    font-size: 5vw;
    margin: 0;
    width: 95%;
  }

  .Ideology .IdeoDiv .ideoImg {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 0;
    transform: scale(2.8) translate(0%, 0%);
    z-index: 0;
  }

  .Ideology .IdeoDiv .ideoImg img {
    width: 100%;
    position: relative;
    z-index: 2;
    transform: scale(0.5) translate(0%, 30%);
  }

  .Ideology .IdeoDiv:nth-child(2) {
    flex-direction: column;
  }

  .Instruction {
    width: 100vw;
    min-height: 80vh;
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin-bottom: 0vh;
  }

  .Instruction .InstructionImg {
    width: 40vw;
    height: 45vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: scale(2.5);
  }

  .Instruction .InstructionImg img {
    width: 80%;
    transform: skew(10deg, 0deg) scale(1);
    filter: drop-shadow(-5px 5px 0px var(--color2));
    z-index: 0;
    margin-left: 0vw;
  }

  .Instruction .instructionDiv {
    display: none;
  }

  .Instruction .instructionDivMobile {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 20vh;
    align-items: center;
  }

  .Instruction .instructionDivMobile .numInstructionMobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Instruction .instructionDivMobile .numInstructionMobile span {
    width: 10vw;
    height: 10vw;
    background: #4754a1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: 'Cera';
    color: var(--color5);
    font-size: 6vw;
    filter: grayscale(0.8);
  }

  .Instruction .instructionDivMobile .numInstructionMobile .active {
    filter: grayscale(0);
  }

  .Instruction .instructionDivMobile p {
    width: 90%;
    font-family: 'Inter';
    font-size: 5vw;
    color: var(--color5);
  }

  .Instruction .instructionDivMobile p span {
    font-family: 'Cera';
    font-size: 4vw;
    color: #4754a1;
    text-shadow: 2px -1px 0 var(--color5);
  }
}